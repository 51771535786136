<template>
  <div class="pageContol listWrap templateList rollCallSet">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$toPath('/web/sjz/jg/classList')">班级管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">点名设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexcb">
          <div class="studentnum">
            <span>未分组学员：{{ungroupUserCount}}</span>
          </div>
          <div class="df ci-full">
            <el-button class="bgc-bv" round @click="doGroup()">学员分组</el-button>
            <el-button class="bgc-bv" round @click="fastCall">快速点名设置</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <!-- <el-table-column label="序号" align="center" type="index" :index="indexMethod" /> -->
              <el-table-column label="组别" align="center" show-overflow-tooltip prop="groupName" />
              <el-table-column label="人数" align="center" show-overflow-tooltip prop="userNum" />
              <el-table-column label="点名状态" align="center" show-overflow-tooltip prop="mobile">
                <template slot-scope="scope">{{scope.row.dotNum>0?'已设置':'未设置'}}</template>
              </el-table-column>
              <el-table-column label="点名次数" align="center" show-overflow-tooltip prop="dotNum" />
              <el-table-column label="操作" align="center" width="400px">
                <div slot-scope="scope" class="flexcc">
                  <div style="margin-right:10px">
                    <el-button
                      style="padding:0px 15px"
                      type="text"
                      size="mini"
                      @click="doSet(scope.row)"
                    >点名设置</el-button>
                  </div>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <div class="flexjc btn_box">
          <el-button class="bgc-bv" size="small" @click="docencal()">取消</el-button>
          <el-button class="bgc-bv" size="small" @click="dosubmit">提交申请</el-button>
          <el-button class="bgc-bv tip_btn" size="small" @click="clickMe">不懂就点我</el-button>
        </div>
      </div>
    </div>
    <el-dialog :before-close="close" title="快速点名设置" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="groupForm" style="width:100%" ref="groupForm">
        <el-form-item label="分组数量：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.groupNum"></el-input>
            <span style="margin-left:5px">组</span>
          </div>
        </el-form-item>
        <el-form-item label="点名次数：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.rollCallNum"></el-input>
            <span style="margin-left:5px">次</span>
          </div>
        </el-form-item>
        <el-form-item label="时间间隔：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.timeInterval"></el-input>
            <span style="margin-left:5px">分</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="close">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doAjax">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :before-close="closeTip"
      title="操作说明"
      top="10px"
      :visible.sync="rollSetdialog"
      width="70%"
      class="dialogtip"
    >
      <div class="flexdc">
        <div class="rollCall_inner flexdc">
          <h3>方法一：手动设置</h3>
          <div class="flex1"  style="display:flex; height: 100%;flex: 1; justify-content: space-between;">
            <div style="display:flex;flex-direction: column;    width: 30%;"  >
              <p>① 第一步</p>
              <img src="@/assets/rollcall_1.png" alt />
              <span class="w_100" >
                点击右上角
                <span class="importColor">“学员分组”</span> 按钮，打开学员分组页面，进行学员分组
              </span>
            </div>
            <div style="display:flex;flex-direction: column;    width: 30%;"  >
              <p>②第二步</p>
              <img src="@/assets/rollcall_2.png" alt />
              <span class="w_100">在分组列表中对每组进行点名设置</span>
            </div>
            <div style="display:flex;flex-direction: column;    width: 30%;"  >
              <p>③第三步</p>
              <img src="@/assets/rollcall_3.png" alt />
              <span class="w_100">
                点击页面下方的
                <span class="importColor">“提交申请”</span>按钮，提交开班申请
              </span>
            </div>
          </div>
        </div>
        <div class="rollCall_inner">
          <h3>方法二：快速设置</h3>
          <div class=" flex1" style="display:flex;justify-content: space-between;">
            <div style="display:flex;flex-direction: column;" >
              <p>①第一步</p>
              <div style="display:flex;justify-content: space-between;">
                <img src="@/assets/rollcall_4.png" alt style="width:43%" />
                <img src="@/assets/rollcall_5.png" alt  style=" width: 43%;margin-right: 5rem;margin-left: 2rem;"/>
              </div>
              <span class="w_100" style="width:90%;">
                点击右上角
                <span class="importColor">“快速点名设置”</span>按钮，输入设置的基本参数
              </span>
            </div>

            <div style="display:flex;flex-direction: column;"  >
              <p>②第二步</p>
              <img src="@/assets/rollcall_6.png" alt />
              <span class="w_100">
                快速点名设置之后，点
                <span class="importColor">“提交申请”</span>按钮，提交开班申请
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "sjz/jg/RollCallSet",
  components: {
    Empty
    // PageNum
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      ungroupUserCount: 0,
      groupForm: {
        groupNum: "",
        rollCallNum: "",
        timeInterval: ""
      },
      projectId: this.$route.query.projectId,
      rollSetdialog: false,
      showDialog: this.$route.query.showDialog || false
    };
  },
  watch: {
    tableData: function(val) {
      if (this.showDialog && !val.length) {
        this.dialogFormVisible = true;
      }
    }
  },
  computed: {},
  created() {},
  methods: {
    getData() {
      const params = {
        projectId: this.projectId
      };
      this.$post("/biz/roll/group/projectRollCall", params).then(res => {
        if (res.status == 0) {
          const data = res.data || [];
          this.tableData = data.groupList;
          this.ungroupUserCount = data.ungroupUserCount;
        }
      });
    },
    // row 操作
    doSet(row) {
      this.$router.push({
        path: "/web/sjz/jg/RollCallSetDetail",
        query: {
          groupId: row.groupId,
          projectId: row.projectId,
          userNum: row.userNum,
          groupName: row.groupName
        }
      });
    },
    // 学员分组
    doGroup() {
      this.$router.push({
        path: "/web/sjz/jg/studentGroup",
        query: {
          // projectId: "1173"
          projectId: this.projectId
        }
      });
    },
    check() {
      let chek = true;
      if (this.ungroupUserCount > 0) {
        this.$message.error("当前存在未分组学员，请进行分组后再提交申请");
        chek = false;
        return chek;
      }
      if (this.tableData.length && chek) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].userNum == 0) {
            this.$message.error(
              "当前分组存在人数为空的分组，请删除空分组或添加学员再提交申请"
            );
            chek = false;
            break;
          }
          if (this.tableData[i].dotNum == 0) {
            this.$message.error(
              "当前分组存在未设置的点名状态，请设置后再提交申请"
            );
            chek = false;
            break;
          }
        }
        return chek;
      }
    },
    // 提交申请
    dosubmit() {
      // console.log(this.check());
      if (this.check()) {
        this.$router.push({
          path: "/web/sjz/jg/rollCallView",
          query: {
            projectId: this.projectId,
            stu: "view"
          }
        });
      }
    },
    // 快速点名
    fastCall() {
      this.dialogFormVisible = true;
    },
    doAjax() {
      const reg = /^[1-9]?\d*$/;
      if (this.groupForm.groupNum && !reg.test(this.groupForm.groupNum)) {
        this.$message({
          type: "warning",
          message: "分组数应为正整数"
        });
        return;
      }
      if (this.groupForm.rollCallNum && !reg.test(this.groupForm.rollCallNum)) {
        this.$message({
          type: "warning",
          message: "点名次数应为正整数"
        });
        return;
      }
      if (
        this.groupForm.timeInterval &&
        !reg.test(this.groupForm.timeInterval)
      ) {
        this.$message({
          type: "warning",
          message: "时间间隔应为正整数"
        });
        return;
      }
      const params = {
        ...this.groupForm,
        projectId: this.projectId
      };
      this.$post("/biz/roll/group/quickSetup", params)
        .then(res => {
          this.getData();
          this.dialogFormVisible = false;
          this.$router.push({
            path: "/web/sjz/jg/rollCallView",
            query: {
              projectId: this.projectId,
              stu: "view"
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    //dialog关闭
    close() {
      this.$refs.groupForm.resetFields();
      this.dialogFormVisible = false;
    },
    // 取消
    docencal() {
      this.$router.push({
        path: "/web/sjz/jg/classList"
      });
    },
    // 不懂就点我
    clickMe() {
      this.rollSetdialog = true;
    },
    // 关闭说明弹窗
    closeTip() {
      this.rollSetdialog = false;
    }
  }
};
</script>
<style lang="less">
.rollCallSet {
  .btn_box {
    .tip_btn {
      background-color: #e36566;
      border-color: #e36566;
    }
  }
  .dialogtip {
    .el-dialog__body {
      padding: 20px;
    }
    .rollCall_inner {
      margin-bottom: 1rem;
      background-color: #f2f2f2;
      border-radius: 0.6rem;
      padding: 1.25rem 2rem;
      h3 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1.3125rem;
        color: #666;
        font-size: 1rem;
        align-self: start;
      }
      img {
        width:100%;
      }
    }
    .rollCall_inner:last-child {
      margin: 0;
    }
    span.importColor {
      color: #5c6ce8;
    }
    span.w_100 {
      margin-top: 1rem;
      color: #666;
      font-size: 0.875rem;
      text-align: center;
      // height: 3.125rem;
      // width: 23.125rem;
    }
  }
}
</style>
<style lang="less" scoped>
.studentnum {
  padding-left: 20px;
  font-size: 14px;
}
</style>

